import React from "react"
import styled from "styled-components"

export function MenuIcons({ src }) {
  return <ImgIcon src={src} alt={src} />
}
const ImgIcon = styled.img`
  margin: 0 1.5rem 0 0;
  width: 2.6rem;
  height: 2.6rem;
`
