import React from "react"
import styled from "styled-components"

import RocketSvg from "../../images/svgs/rocket.svg"
import { CatchyHeadings, Paragraphs } from "../typography"
import { Button } from "../elements"

export function HeaderPage({
  capitle,
  catchy = "It’s time to exapnd.",
  src = RocketSvg,
  // title = "Leading digital marketing agency in Melbourne & Sydney",
  tagline = "The right digital marketing approach for you.",
}) {
  return (
    <Cover>
      <HeaderContent>
        <CatchyHeadings capitle={capitle}>{catchy}</CatchyHeadings>
        {/* <Heading>{title}</Heading> */}
        <Details>{tagline}</Details>
        <Button to="/contact/">Contact us</Button>
      </HeaderContent>
      <Rocket src={src} alt="Seo Boost" />
    </Cover>
  )
}
const Details = styled.h1`
  font-weight: 500;
  font-size: 3.5rem;
  line-height: 135%;
  padding: 1rem 0.2rem;
  color: ${props => props.theme.color.dark};
  @media only screen and (max-width: 75em) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 2.5rem;
  }
`
const Cover = styled.section`
  background-color: ${({ theme }) => theme.color.bgFirst};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0 3rem 0;
  justify-content: space-around;
  align-items: center;
`

const HeaderContent = styled.div`
  width: 50%;
  height: auto;
  @media only screen and (max-width: 75em) {
    order: 0;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
    order: 1;
    padding: 5rem;
  }
  @media only screen and (max-width: 37.5em) {
    order: 1;
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
`
const Heading = styled.h1`
  text-transform: capitalize;
  visibility: visible;
  font-weight: bold;
  font-size: 5rem;
  line-height: 140%;
  color: ${props => props.color || props.theme.color.dark};
  @media only screen and (max-width: 37.5em) {
    font-size: 3rem;
    line-height: 140%;
    padding: 1rem 0 0.5rem 0;
  }
  @media only screen and (min-width: 112.5em) {
    font-size: 5rem;
    line-height: 140%;
    letter-spacing: 1.5px;
    padding: 0.5rem 0;
  }
`

const Rocket = styled.img`
  width: 45rem;
  height: auto;

  @media only screen and (max-width: 75em) {
    width: 40rem;
    order: 0;
  }
  @media only screen and (max-width: 56.25em) {
    width: 45rem;
    order: 0;
  }
  @media only screen and (max-width: 37.5em) {
    width: 28rem;
    order: 0;
    padding: 5rem 0 0 0;
    /* display: none; */
  }
  @media only screen and (min-width: 112.5em) {
    width: 60rem;
  }
`
