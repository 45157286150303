import React from "react"
import styled from "styled-components"

import RocketSvg from "../../images/svgs/rocket.svg"
import { CatchyHeadings, Paragraphs } from "../typography"
import { Button } from "../elements"

export function HeaderMain({
  catchy = "It’s time to exapnd.",
  image = RocketSvg,
  title = "Leading digital marketing agency in Melbourne & Sydney",
  details = "The right digital marketing approach for you.",
}) {
  return (
    <Cover>
      <HeaderContent>
        <CatchyHeadings>{catchy}</CatchyHeadings>

        <Heading>
          {title}
          {/* <Typewriter
            options={{
              strings: [
                // "Make this your year.",
                // "Watch your business soar.",
                "Leading digital marketing agency in Melbourne & Sydney",
                // "Customised digital marketing to grow your business",
                // "Growing your business through digital power",
                // "Digital marketing, designed for you.",
              ],
              autoStart: true,
              loop: false,
              // deleteSpeed: "500ms",
              cursor: "",
            }}
          /> */}
        </Heading>
        <Paragraphs>{details}</Paragraphs>
        <Button to="/contact/">Contact us</Button>
      </HeaderContent>
      <Rocket src={image} alt="Seo Boost" />
    </Cover>
  )
}
const Details = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`
const Cover = styled.section`
  background-color: ${({ theme }) => theme.color.bgFirst};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0 0 0;
  justify-content: space-evenly;
  align-items: center;
`

const HeaderContent = styled.div`
  width: 40%;
  height: auto;
  @media only screen and (max-width: 75em) {
    order: 0;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
    order: 1;
    padding: 5rem;
  }
  @media only screen and (max-width: 37.5em) {
    order: 1;
    width: 100%;
    padding: 1rem;
    text-align: center;
  }
`
const Heading = styled.h1`
  text-transform: capitalize;
  visibility: visible;
  font-weight: bold;
  font-size: 5rem;
  line-height: 7.1rem;
  color: ${props => props.color || props.theme.color.dark};
  @media only screen and (max-width: 37.5em) {
    font-size: 3rem;
    line-height: 4.5rem;
    padding: 1rem 0 0.5rem 0;
  }
  @media only screen and (min-width: 112.5em) {
    font-size: 5rem;
    line-height: 7.1rem;
    letter-spacing: 1.5px;
    padding: 0.5rem 0;
  }
`

const Rocket = styled.img`
  width: 45rem;
  height: auto;

  @media only screen and (max-width: 75em) {
    width: 40rem;
    order: 0;
  }
  @media only screen and (max-width: 56.25em) {
    width: 45rem;
    order: 0;
  }
  @media only screen and (max-width: 37.5em) {
    width: 32rem;
    order: 0;
    padding: 5rem 0 0 0;
    /* display: none; */
  }
  @media only screen and (min-width: 112.5em) {
    width: 60rem;
  }
`
