import React from "react"
import styled from "styled-components"

export function Spacer({ space, children }) {
  return <Space space={space}>{children}</Space>
}

const Space = styled.div`
  display: inline-block;
  padding-bottom: ${props => props.space || "1.2rem"};
`
