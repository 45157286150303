import React from "react"
import styled from "styled-components"

export function CatchyHeadings({ children, capitle }) {
  return (
    <Cover>
      <Text capitle={capitle}>{children}</Text>
    </Cover>
  )
}
export function LinksHeading({ children }) {
  return <LinkHeading>{children}</LinkHeading>
}

const Cover = styled.div`
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.color.catchy};
`

const Text = styled.h2`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1rem;
  text-transform: ${props => (props.capitle ? "" : "capitalize")};
  color: ${props => props.theme.color.dark};
`
const LinkHeading = styled.h2`
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 4rem;
  text-transform: capitalize;
  color: ${props => props.theme.color.dark};
`
