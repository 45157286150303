import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import {
  LogoDark,
  Button,
  ContactAction,
  MenuIcons,
  CtaFaq,
  CtaMail,
  CtaButton,
} from "../elements"
import { Link } from "gatsby"
import { Home, ChevronDown, CheckSquare, Menu, X } from "react-feather"

import webdesign from "../../images/icons-product/webdesign.svg"
import socialmedia from "../../images/icons-product/socialmedia.svg"
import mobileapp from "../../images/icons-product/mobileapp.svg"
import ecommerce from "../../images/icons-product/ecommerce.svg"
import digitalmarketing from "../../images/icons-product/digitalmarketing.svg"
import branding from "../../images/icons-product/branding.svg"
import content from "../../images/icons-product/content.svg"

export function Navbar() {
  const [overlayServices, setOverlayServices] = useState("none")
  const [overlayResources, setOverlayResources] = useState("none")
  const [mobileMenu, setMobileMenu] = useState("hidden")
  const [displaySubLinks, setDisplaySubLinks] = useState("block")
  const [displayResources, setDisplayResources] = useState("block")
  const [menuBackground, setMenuBackground] = useState("none")
  const [menuShadow, setMenuShadow] = useState("none")
  const [show, setShow] = useState("none")
  const [display, setDisplay] = useState("none")

  function scrollMenu() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setShow("block")
      setDisplay("block")
      setMenuBackground("white")
      setMenuShadow("drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))")
    } else {
      setMenuBackground("none")
      setMenuShadow("none")
      setShow("none")
      setDisplay("none")
    }
  }

  useEffect(() => {
    window.onscroll = function () {
      scrollMenu()
    }
  })

  function dropdownServices() {
    setOverlayServices("block")
  }
  function closeFunction() {
    if (overlayServices === "block") {
      setOverlayServices("none")
    }
    if (overlayResources === "block") {
      setOverlayResources("none")
    }
  }
  function dropdownResources() {
    setOverlayResources("block")
  }

  function menuOpen() {
    setMobileMenu("visible")
  }
  function menuClose() {
    setMobileMenu("hidden")
  }

  function showServices() {
    if (displaySubLinks === "none") {
      setDisplaySubLinks("block")
    } else if (displaySubLinks === "block") {
      setDisplaySubLinks("none")
    }
  }
  function showResources() {
    if (displayResources === "none") {
      setDisplayResources("block")
    } else if (displayResources === "block") {
      setDisplayResources("none")
    }
  }

  return (
    <>
      <CtaFaq display={display} />
      <CtaMail show={show} />
      <CtaButton />
      <Navigation menuBackground={menuBackground} menuShadow={menuShadow}>
        <Cover>
          <Logo to="/">
            <LogoDark />
          </Logo>
          <Navlinks>
            <Navlink
              onMouseOver={closeFunction}
              activeClassName="active"
              to="/"
            >
              <HomeIcon />
            </Navlink>

            <Navlink activeClassName="active" onMouseOver={dropdownServices}>
              Services <DropdownIcon />
            </Navlink>
            <ServicesBox overlayServices={overlayServices}>
              <OverlayServices>
                <Navlink
                  activeClassName="active"
                  to="/digital-marketing-services/"
                >
                  <MenuIcons src={digitalmarketing} /> Digital Marketing
                  Services
                </Navlink>
                <Navlink
                  activeClassName="active"
                  to="/website-design-development/"
                >
                  <MenuIcons src={webdesign} /> Website Design & Development
                </Navlink>
                <Navlink
                  activeClassName="active"
                  to="/social-media-management/"
                >
                  <MenuIcons src={socialmedia} /> Social Media Management
                </Navlink>
                <Navlink activeClassName="active" to="/e-commerce-development/">
                  <MenuIcons src={ecommerce} /> eCommerce Development
                </Navlink>
                <Navlink activeClassName="active" to="/mobile-app-development/">
                  <MenuIcons src={mobileapp} /> Mobile App Development
                </Navlink>
                <Navlink
                  activeClassName="active"
                  to="/brand-identity-development/"
                >
                  <MenuIcons src={branding} /> Brand Identity Development
                </Navlink>
                <Navlink
                  activeClassName="active"
                  to="/customised-content-writing-blogging/"
                >
                  <MenuIcons src={content} /> Customised Content Writing &
                  Blogging
                </Navlink>
              </OverlayServices>
            </ServicesBox>
            <Navlink
              onMouseOver={closeFunction}
              activeClassName="active"
              to="/about-us/"
            >
              Who we are?
            </Navlink>
            <Navlink
              onMouseOver={closeFunction}
              activeClassName="active"
              to="/portfolio/"
            >
              Our work
            </Navlink>
            <Navlink
              onMouseOver={closeFunction}
              activeClassName="active"
              to="/contact/"
            >
              Contact
            </Navlink>
            <Navlink onMouseOver={dropdownResources} activeClassName="active">
              Resources <DropdownIcon />
            </Navlink>
            <ResourceBox overlayResources={overlayResources}>
              <OverlayServices>
                <Navlink activeClassName="active" to="/faq/">
                  FAQ
                </Navlink>
                {/* <Navlink activeClassName="active" to="/conditions/">
                  Critical Information
                </Navlink> */}
                <Navlink activeClassName="active" to="/blog/">
                  Blogs
                </Navlink>
              </OverlayServices>
            </ResourceBox>
            <Button
              onMouseOver={closeFunction}
              title="Get Quote"
              to="/contact/"
            >
              <Quote />
            </Button>
          </Navlinks>
        </Cover>
      </Navigation>
      <MobileNavigation menuBackground={menuBackground} menuShadow={menuShadow}>
        <MobileCover>
          <Logo to="/">
            <LogoDark />
          </Logo>
          <Burger>
            <MenuButton onClick={menuOpen} />
          </Burger>
          <MobileLinks display={mobileMenu}>
            <CloseCover onClick={menuClose}>
              <CloseButton />
            </CloseCover>
            <LinkCover>
              <MobileNavLink activeClassName="active" to="/about-us/">
                Who we are?
              </MobileNavLink>
              <MobileNavLink activeClassName="active" to="/portfolio/">
                Our work
              </MobileNavLink>
              <SubCover onClick={showResources}>
                <MobileNavLink activeClassName="active">
                  Resources <MobileDropdownIcon />
                </MobileNavLink>
              </SubCover>
              <SubLinksResources displayResources={displayResources}>
                <MobileSubLink activeClassName="active" to="/blog/">
                  Blogs
                </MobileSubLink>
                <MobileSubLink activeClassName="active" to="/faq/">
                  FAQ
                </MobileSubLink>
              </SubLinksResources>

              <SubCover onClick={showServices}>
                <MobileNavLink activeClassName="active">
                  Services <MobileDropdownIcon />
                </MobileNavLink>
              </SubCover>

              <SubLinks displaySubLinks={displaySubLinks}>
                <MobileSubLink
                  activeClassName="active"
                  to="/digital-marketing-services/"
                >
                  Digital Marketing Services
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/website-design-development/"
                >
                  Website Design & Development
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/social-media-management/"
                >
                  Social Media Management
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/e-commerce-development/"
                >
                  eCommerce Development
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/mobile-app-development/"
                >
                  Mobile App Development
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/brand-identity-development/"
                >
                  Brand Identity Development
                </MobileSubLink>
                <MobileSubLink
                  activeClassName="active"
                  to="/customised-content-writing-blogging/"
                >
                  Customised Content Writing & Blogging
                </MobileSubLink>
              </SubLinks>

              <MobileNavLink activeClassName="active" to="/contact/">
                Contact
              </MobileNavLink>

              <ActionFunctionsCover>
                <ContactToActionCover>
                  <ContactAction />
                </ContactToActionCover>
              </ActionFunctionsCover>
            </LinkCover>
          </MobileLinks>
        </MobileCover>
      </MobileNavigation>
    </>
  )
}

const ServicesBox = styled.div`
  display: ${props => props.overlayServices};
  position: fixed;
  margin: 6rem 4rem;
  padding: 2rem 0.5rem 1.5rem 2rem;
  background-color: ${props => props.theme.color.light};
`

const ResourceBox = styled.div`
  display: ${props => props.overlayResources};
  margin: 6rem 47rem;
  position: fixed;
  padding: 2rem 0.5rem 1.5rem 2rem;
  background-color: ${props => props.theme.color.light};
`

const OverlayServices = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`

const easeIn = keyframes`
  from {
    transform: translateY(-0.5rem);
  }
 
`

const Navigation = styled.nav`
  width: 100%;
  height: auto;
  position: fixed;
  background-color: ${props => props.menuBackground};
  filter: ${props => props.menuShadow};
`

const Cover = styled.div`
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 75em) {
    display: none;
  }
  @media only screen and (max-width: 56.25em) {
    display: none;
  }
  @media only screen and (max-width: 37.5em) {
    display: none;
  }
`

const Logo = styled(Link)`
  width: 23rem;
  padding: 1rem 1rem;
`

const Navlinks = styled.div`
  display: flex;
`

const Navlink = styled(Link)`
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
  text-decoration: none;
  padding: 1rem 2rem 0 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.color.dark};

  &:hover {
    color: ${props => props.theme.color.highlight};
  }
`
const HomeIcon = styled(Home)`
  width: 2rem;
  height: auto;
`
const DropdownIcon = styled(ChevronDown)`
  width: 2rem;
  height: auto;
`
const Quote = styled(CheckSquare)`
  width: 2rem;
  height: auto;
  margin: 0 0 0 1rem;
`

const MobileNavigation = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 800000000;
  top: 0;
  left: 0;
  display: none;
  background-color: ${props => props.menuBackground};
  filter: ${props => props.menuShadow};
  @media only screen and (max-width: 75em) {
    display: block;
  }
  @media only screen and (max-width: 56.25em) {
    display: block;
  }
  @media only screen and (max-width: 37.5em) {
    display: block;
  }
`

const Burger = styled.div``
const MenuButton = styled(Menu)`
  cursor: pointer;
  width: 3rem;
  height: auto;
  margin: 3.8rem 1rem 0 0;
  color: ${props => props.theme.color.dark};
`
const CloseButton = styled(X)`
  cursor: pointer;
  width: 6rem;
  height: auto;
  color: ${props => props.theme.color.highlight};
`
const CloseCover = styled.div`
  position: absolute;
  z-index: 200;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
  background-color: ${props => props.theme.color.light};
`
const MobileCover = styled.div`
  margin: 0 auto;
  width: 97%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const MobileLinks = styled.div`
  opacity: 1;
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  visibility: ${props => props.display};
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.color.light};
  box-shadow: ${props => props.theme.shadow.button};
  @media only screen and (max-width: 75em) {
    width: 60vw;
    height: 100vh;
  }
  @media only screen and (max-width: 56.25em) {
    width: 60vw;
    height: 100vh;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: 100vh;
  }
`
const LinkCover = styled.div`
  overflow-y: scroll;
  scroll-behavior: smooth;
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: column;
  padding: 8rem 1.5rem 1rem 1.5rem;
`

const MobileNavLink = styled(Navlink)`
  width: 100%;
  font-size: 3rem;
  padding: 1.7rem 0;
  justify-content: space-between;
`
const MobileDropdownIcon = styled(DropdownIcon)`
  width: 3rem;
  height: auto;
`

const ActionFunctionsCover = styled.div``

const ContactToActionCover = styled.div`
  padding: 0.5rem 0;
`

const SubCover = styled.div`
  cursor: pointer;
`

const SubLinks = styled.div`
  opacity: 1;
  padding: 0 0 0 1rem;
  display: ${props => props.displaySubLinks};
`
const SubLinksResources = styled.div`
  padding: 0 0 0 1rem;
  display: ${props => props.displayResources};
`

const MobileSubLink = styled(MobileNavLink)`
  font-size: 2rem;
  padding: 0.4rem 0;
  animation: ${easeIn} 0.5s ease-in-out;
`
