import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export function Button({ title, children, to, onMouseOver }) {
  return (
    <Cover onMouseOver={onMouseOver} to={to}>
      {title}
      {children}
    </Cover>
  )
}

const Cover = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.theme.color.highlight};
  color: ${props => props.theme.color.white};
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  letter-spacing: 2px;
  font-family: ${props => props.theme.font.normal};
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.7rem;
  &:hover {
    box-shadow: ${props => props.theme.shadow.button};
  }
`
