import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { Facebook, Linkedin, Youtube, Twitter } from "react-feather"

export function SocialMedia() {
  return (
    <Cover>
      <LinkinLink to="/">
        <FBIcon />
      </LinkinLink>
      <FacebookLink to="/">
        <LIIcon />
      </FacebookLink>
      <YoutubeLink to="/">
        <YTIcon />
      </YoutubeLink>
      <TwitterLink to="/">
        <TWIcon />
      </TwitterLink>
    </Cover>
  )
}

const Cover = styled.div`
  display: inline-flex;
  flex-direction: row;
`

const LinkinLink = styled(Link)`
  margin: 0 3rem 0 0;
`
const FacebookLink = styled(LinkinLink)``
const YoutubeLink = styled(LinkinLink)``
const TwitterLink = styled(LinkinLink)``

const FBIcon = styled(Facebook)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
`
const LIIcon = styled(Linkedin)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
`
const YTIcon = styled(Youtube)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
`
const TWIcon = styled(Twitter)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
`
