import React from "react"
import styled from "styled-components"
import { CatchyHeadings, SubTitle } from "../typography"
import { ServicesCard } from "../elements"

import branding from "../../images/Product-Services-Svgs/branding.svg"
import contentBlogs from "../../images/Product-Services-Svgs/content-blogs.svg"
import digitatMarketing from "../../images/Product-Services-Svgs/digital-marketing.svg"
import eCommerce from "../../images/Product-Services-Svgs/e-commerce.svg"
import mobileApp from "../../images/Product-Services-Svgs/mobile-app.svg"
import socialmangement from "../../images/Product-Services-Svgs/social-media-management.svg"
import webdevelopment from "../../images/Product-Services-Svgs/website-development.svg"
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"

export function Services() {
  function scrollLeft() {
    document.getElementById("container").scrollLeft += 300
  }
  function scrollRight() {
    document.getElementById("container").scrollLeft -= 300
  }
  return (
    <Section>
      <Wrapper>
        <TopSection>
          <CatchyHeadings> What we offer.</CatchyHeadings>
          <SubTitle>Product & Services</SubTitle>
        </TopSection>
        <MiddelSection id="container">
          <ServicesCard
            to="/digital-marketing-services/"
            src={digitatMarketing}
            title="Digital Marketing Agency"
            detail="Implement proven digital marketing strategies to increase brand awareness, drive website traffic, and generate more sales and revenue."
          />
          <ServicesCard
            to="/social-media-management/"
            src={socialmangement}
            title="Social Media Management"
            detail="Plan, create, publish, and promote content on the best social media platforms to build a loyal community of followers and customers."
          />
          <ServicesCard
            to="/website-design-development/"
            src={webdevelopment}
            title="Website Design Development"
            detail="Create a powerful website that provides the answers your ideal customer is looking for and establishes you as an industry leader."
          />
          <ServicesCard
            to="/mobile-app-development/"
            src={mobileApp}
            title="Mobile App Development"
            detail="Generate more revenue by developing a mobile app to make your products and services even easier for your customers to access."
          />
          <ServicesCard
            to="/e-commerce-development/"
            src={eCommerce}
            title="eCommerce Development"
            detail="Drive traffic and sales with a user-friendly online shopping platform that engages your customer base. "
          />
          {/* <ServicesCard
            to="/brand-identity-development/"
            src={branding}
            title="Brand Identity"
            detail="Make a statement and set your business apart from the crowd by using design, colour, and storytelling to build your brand identity."
          /> */}
          <ServicesCard
            to="/customised-content-writing-blogging/"
            src={contentBlogs}
            title="Customised Content Writing & Blogging"
            detail="Create a powerful website that provides the answers your ideal customer is looking for and establishes you as an industry leader."
          />
        </MiddelSection>
        <ArrowLeft onClick={scrollLeft} />
        <ArrowRight onClick={scrollRight} />
      </Wrapper>
    </Section>
  )
}

const Section = styled.section`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.light};
`
const ArrowLeft = styled(ArrowLeftCircle)`
  display: none;
  @media only screen and (max-width: 75em) {
    width: 5rem;
    height: auto;
    position: absolute;
    left: 5rem;
  }
  @media only screen and (max-width: 56.25em) {
    width: 5rem;
    height: auto;
    position: absolute;
    left: 5rem;
  }
  @media only screen and (max-width: 37.5em) {
    width: 5rem;
    height: auto;
    position: absolute;
    left: 5rem;
  }
  @media only screen and (min-width: 112.5em) {
    display: none;
  }
`
const ArrowRight = styled(ArrowRightCircle)`
  display: none;
  @media only screen and (max-width: 75em) {
    width: 5rem;
    height: auto;
    position: absolute;
    right: 5rem;
  }
  @media only screen and (max-width: 56.25em) {
    width: 5rem;
    height: auto;
    position: absolute;
    right: 5rem;
  }
  @media only screen and (max-width: 37.5em) {
    width: 5rem;
    height: auto;
    position: absolute;
    right: 5rem;
  }
  @media only screen and (min-width: 112.5em) {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 95%;
  margin: auto auto;
  padding: 6rem 0.5rem;
  text-align: center;
  @media only screen and (max-width: 75em) {
    width: 95%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 95%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 97%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 75%;
  }
`
const TopSection = styled.div``

const MiddelSection = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  scrollbar-width: none;
`
// const MiddelSection = styled.div`
//   padding: 3rem 0;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   scrollbar-width: none;

//   @media only screen and (max-width: 75em) {
//     display: flex;
//     flex-wrap: nowrap;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//     scrollbar-width: none;

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }
//   @media only screen and (max-width: 56.25em) {
//     display: flex;
//     flex-wrap: nowrap;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//     scrollbar-width: none;

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }
//   @media only screen and (max-width: 37.5em) {
//     display: flex;
//     flex-wrap: nowrap;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//     scrollbar-width: none;

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }
//   @media only screen and (min-width: 112.5em) {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: center;
//     scrollbar-width: none;
//   }
// `
