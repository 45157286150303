import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export function Title({ children, color }) {
  return <BigTitle color={color}>{children}</BigTitle>
}
export function SubTitle({ children }) {
  return <MediumTitle>{children}</MediumTitle>
}
export function Caption({ children, color }) {
  return <SmallTitle color={color}>{children}</SmallTitle>
}
export function ServiceTitle({ children }) {
  return <SerTitle>{children}</SerTitle>
}
export function FooterLinks({ children, to }) {
  return (
    <Links activeClassName="active" to={to}>
      {children}
    </Links>
  )
}
export function TinyLinks({ children, to }) {
  return (
    <Conditions activeClassName="active" to={to}>
      {children}
    </Conditions>
  )
}

const BigTitle = styled.h1`
  font-weight: bold;
  font-size: 5rem;
  line-height: 7.1rem;
  color: ${props => props.color || props.theme.color.dark};
  @media only screen and (max-width: 37.5em) {
    font-size: 3.5rem;
    line-height: 5.1rem;
  }
`
const MediumTitle = styled(BigTitle)`
  font-weight: 600;
  font-size: 4.2rem;
  line-height: 6.1rem;
  text-transform: capitalize;
  color: ${props => props.theme.color.dark};
`
const SmallTitle = styled(MediumTitle)`
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.9rem;
  color: ${props => props.color || props.theme.color.dark};
`
const SerTitle = styled(SmallTitle)`
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.2rem;
  color: ${props => props.theme.color.dark};
`
const Links = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.025em;
  color: ${props => props.theme.color.dark};
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
const Conditions = styled(Link)`
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 3rem;

  color: ${props => props.theme.color.complimentry};
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.8rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
