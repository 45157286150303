import React from "react"
import styled from "styled-components"
import { Button } from "./button"

export function Form() {
  return (
    <Cover>
      <InputCover action="">
        <TextBox
          type="text"
          id="fname"
          name="fullname"
          placeholder="Your name"
        />
        <TextBox
          captile
          type="url"
          id="fweb"
          name="website"
          placeholder="www.yourwebsite.com.au"
        />
        <TextBox type="email" id="email" name="email" placeholder="Email" />
        <TextBox
          type="tel"
          id="number"
          name="number"
          placeholder="Phone number"
        />
        <InputTextArea type="text" name="meassage" placeholder="message" />
      </InputCover>
      <Button>Send</Button>
    </Cover>
  )
}

const Cover = styled.div`
  width: 100%;
  height: auto;
  padding: 0 1.5rem 2rem 1.5rem;
  text-align: center;
`
const InputCover = styled.form`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem 0;
`
const InputTextArea = styled.textarea`
  font-size: 2rem;
  font-family: "Open Sans", sans-serif;
  min-width: 100%;
  max-width: 99%;
  padding: 14px 20px;
  text-transform: ${props => (props.captile ? "" : "capitalize")};
  margin: 8px 0;
  border-radius: 4px;
  border: none;
  background-color: white;
  border: 1px solid ${props => props.theme.color.highlight};
`

const TextBox = styled.input`
  font-size: 2rem;
  width: 100%;
  background-color: white;
  text-transform: ${props => (props.captile ? "" : "capitalize")};
  color: ${props => props.theme.color.dark};
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.highlight};
`
{
  /* <form action="/action_page.php">
<label for="fname">First Name</label>
<input type="text" id="fname" name="firstname" placeholder="Your name..">

<label for="lname">Last Name</label>
<input type="text" id="lname" name="lastname" placeholder="Your last name..">

<label for="country">Country</label>
<select id="country" name="country">
  <option value="australia">Australia</option>
  <option value="canada">Canada</option>
  <option value="usa">USA</option>
</select>

<input type="submit" value="Submit">
</form> */
}
