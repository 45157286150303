import React from "react"
import styled from "styled-components"

import {
  LinksHeading,
  FooterLinks,
  TinyLinks,
  TinyParagraph,
} from "../../components/typography"
import { LogoDark, SocialMedia, ContactAction } from "../elements"

export function Footer() {
  return (
    <Section>
      <Wrapper>
        <TopSection>
          <BradSection>
            <LogoCover>
              <LogoDark />
            </LogoCover>
            <SocialCover>
              <SocialMedia />
            </SocialCover>
            <ContactSection>
              <ContactAction />
            </ContactSection>
          </BradSection>

          <ServicesLinkSection>
            <LinksHeading>Services</LinksHeading>
            <LinkWrapper>
              <LinkCover>
                {/* <FooterLinks to="/digital-marketing-services/">SEO</FooterLinks> */}
                <FooterLinks to="/digital-marketing-services/">
                  Digital marketing services
                </FooterLinks>
                <FooterLinks to="/website-design-development/">
                  Website design & development
                </FooterLinks>
                <FooterLinks to="/social-media-management/">
                  Social media management
                </FooterLinks>
                <FooterLinks to="/e-commerce-development/">
                  eCommerce development
                </FooterLinks>
                <FooterLinks to="/mobile-app-development/">
                  Mobile app development
                </FooterLinks>
                <FooterLinks to="/brand-identity-development/">
                  Brand Identity management
                </FooterLinks>
                <FooterLinks to="/customised-content-writing-blogging/">
                  Content writing & Blogging
                </FooterLinks>
                {/* <FooterLinks to="/digital-marketing-services/">
                  Email marketing
                </FooterLinks> */}
              </LinkCover>
              <LinkCover>
                {/* <FooterLinks to="/mobile-app-development/">
                  Mobile app development
                </FooterLinks>
                <FooterLinks to="/brand-identity-development/">
                  Brand Identity management
                </FooterLinks>
                <FooterLinks to="/customised-content-writing-blogging/">
                  Content writing & Blogging
                </FooterLinks> */}
                {/* <FooterLinks to="/social-media-management/">
                  Social media Ads
                </FooterLinks>
                <FooterLinks to="/social-media-management/">
                  Google Ads
                </FooterLinks>
                <FooterLinks to="/customised-content-writing-blogging/">
                  Blogging
                </FooterLinks> */}
              </LinkCover>
            </LinkWrapper>
          </ServicesLinkSection>

          <QuickLinkSection>
            <LinksHeading>Quick Links</LinksHeading>
            <FooterLinks to="/about-us/">Who we are</FooterLinks>
            <FooterLinks to="/contact/">Contact us</FooterLinks>
            <FooterLinks to="/quote/">Free quote</FooterLinks>
            <FooterLinks to="/portfolio/">Our work</FooterLinks>
            <FooterLinks to="/blog/">Blog</FooterLinks>
            <FooterLinks to="/faq/">FAQ</FooterLinks>
          </QuickLinkSection>
        </TopSection>
        <LegalLinkSection>
          <TinyLinks to="/conditions/">Privacy Policy</TinyLinks>
          <TinyLinks to="/conditions/">Term & Conditions</TinyLinks>
        </LegalLinkSection>
      </Wrapper>
      <CopyRightSection>
        <TinyParagraph>
          All Right reserved © {new Date().getFullYear()} eDigitalHub
        </TinyParagraph>
      </CopyRightSection>
    </Section>
  )
}

const Section = styled.footer`
  padding: 2rem 0rem;
  width: 100%;
  background-color: ${props => props.theme.color.light};
  border-top-width: 0.6px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.color.catchy};
`

const Wrapper = styled.div`
  padding: 2rem 1.5rem;
  width: 97%;
  height: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
`

const TopSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 75em) {
    width: 95%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 37.5em) {
    width: 99%;
    justify-content: flex-start;
  }
  @media only screen and (min-width: 112.5em) {
    width: 75%;
  }
`

const BradSection = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 5rem 0 0;
  @media only screen and (max-width: 75em) {
    margin: 0;
  }
  @media only screen and (max-width: 56.25em) {
    margin: 0 0 2rem 3rem;
  }
  @media only screen and (max-width: 37.5em) {
    margin: 0;
    padding-right: 5rem;
  }
`

const QuickLinkSection = styled.div``

const ServicesLinkSection = styled.div`
  margin: 0 5rem;
  align-self: flex-start;
  @media only screen and (max-width: 75em) {
    margin: 0 2.8rem;
  }
  @media only screen and (max-width: 56.25em) {
    margin: 0 1rem 0 2rem;
  }
  @media only screen and (max-width: 37.5em) {
    margin: 2rem 0;
    /* padding-right: 5rem; */
  }
`

const LegalLinkSection = styled.div`
  width: 30%;
  margin: 0rem auto;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 75em) {
    width: 35%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 35%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
`

const CopyRightSection = styled.div`
  text-align: center;
`

const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
`
const LinkCover = styled.div`
  margin: 0 1.3rem 0 0;
`

const LogoCover = styled.div`
  width: 20rem;
  height: auto;
`

const SocialCover = styled.div`
  padding: 2rem 0;
`
const ContactSection = styled.div``
