import React from "react"
import styled from "styled-components"

export function Line({ color }) {
  return <Color color={color} />
}

const Color = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props => props.color || props.theme.color.catchy};
`
