import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const theme = {
  font: {
    heading: "Jost, sans-serif",
    normal: "Poppins, sans-serif",
  },
  color: {
    dark: "#0F2239",
    catchy: "#F6DB43",
    light: "#FFFFFF",
    white: "#FFFFFF",
    highlight: "#39B85D",
    complimentry: "#4AB2DC",
    bgFirst: "#EAFCFC",
    bgSecond: "#FFFBE9",
  },
  procolors: {
    //Digital Marketing
    digitalLight: "#EAFCFC",
    digitalDark: "#4AB2DC",
    //Social Media Management
    socialLight: "#EAFCFC",
    socialDark: "#4AB2DC",
    //web design and development
    webLight: "#EAFCFC",
    webDark: "#4AB2DC",
    //eCommerce development
    shopLight: "#EAFCFC",
    shopDark: "#4AB2DC",
    // Mobile app development
    appLight: "#EAFCFC",
    appDark: "#4AB2DC",
    // Brand identity
    brandLight: "#EAFCFC",
    brandDark: "#4AB2DC",
    //Content and blogging
    contentLight: "#EAFCFC",
    contentDark: "#4AB2DC",
  },
  // procolors: {
  //   //Digital Marketing
  //   digitalLight: "#FCE4EC",
  //   digitalDark: "#D81B60",
  //   //Social Media Management
  //   socialLight: "#E8EAF6",
  //   socialDark: "#304FFE",
  //   //web design and development
  //   webLight: "#E8F5E9",
  //   webDark: "#00C853",
  //   //eCommerce development
  //   shopLight: "#FBE9E7",
  //   shopDark: "#DD2C00",
  //   // Mobile app development
  //   appLight: "#EDE7F6",
  //   appDark: "#6200EA",
  //   // Brand identity
  //   brandLight: "#E3F2FD",
  //   brandDark: "#2962FF",
  //   //Content and blogging
  //   contentLight: "#FFF8E1",
  //   contentDark: "#FF6F00",
  // },
  shadow: {
    button: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    links: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    text-rendering: geometricPrecision;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.normal};
    font-size: 62.5%;
    color: ${({ theme }) => theme.color.dark};
    @media only screen and (max-width: 75em) {
      font-size: 62%;
    }
    @media only screen and (max-width: 56.25em) {
      font-size: 62%;
    } 
    @media only screen and (max-width: 37.5em) {
      font-size: 52%;
    } 
     @media only screen and (min-width: 112.5em) {
      font-size: 65%;
    } 

  body {
    text-rendering: optimizeLegibility;
  }

  h1,h2{
    font-family: ${({ theme }) => theme.font.heading};

  } 
  h3,h4{
    font-family: ${({ theme }) => theme.font.normal};

  } 
  p {
    font-family: ${({ theme }) => theme.font.normal};
    font-style: normal;
    color:${({ theme }) => theme.color.dark};
  }
  a{
    font-family: ${({ theme }) => theme.font.heading};
    font-size:1.8rem;
    letter-spacing: 0.025em;
    outline: none;
    }
  .active{
      color:${({ theme }) => theme.color.highlight};
   }
  }
`
