import React from "react"
import styled from "styled-components"
import { Caption, MediumParagraphs } from "../typography"
import { Spacer } from "../elements"

export function AboutCard({ src, title, detail, detail1, textalign }) {
  return (
    <Animation
    // data-sal-duration="1500"
    // data-sal="slide-up"
    // data-sal-delay="300"
    // data-sal-easing="ease-out-back"
    >
      <Cover textalign={textalign}>
        <Wrapper>
          <ImageIcon src={src} alt={src} />
          <Caption>{title}</Caption>
          <Spacer />
          <MediumParagraphs>{detail}</MediumParagraphs>
          <MediumParagraphs>{detail1}</MediumParagraphs>
        </Wrapper>
      </Cover>
    </Animation>
  )
}
const Animation = styled.div``

const Cover = styled.div`
  text-align: ${props => props.textalign || "center"};
  width: 70%;
  min-height: 29rem;
  width: auto;
  height: auto;
  background: ${props => props.theme.color.light};
  /* border: 0.2px solid ${props => props.theme.color.complimentry}; */
  border-radius: 10px;
  margin: 1rem 1rem;
  display: flex;
  align-items: center;
`

const Wrapper = styled.div`
  padding: 1rem 1.5rem;
`

const ImageIcon = styled.img`
  width: 8rem;
  height: auto;
  margin: 0 0 1rem 0;
`
