import React from "react"
import styled from "styled-components"

export function CardLeft({ src, alt, title, detail }) {
  return (
    <Cover>
      <Wrapper>
        <IconCover>
          <Icon src={src} alt={alt} />
        </IconCover>
        <DetailCover>
          <ItemTitle>{title}</ItemTitle>
          <ItemDetails>{detail}</ItemDetails>
        </DetailCover>
      </Wrapper>
    </Cover>
  )
}

export function CardRight({ src, alt, title, detail }) {
  return (
    <Cover>
      <Wrapper>
        <DetailCover>
          <ItemTitle>{title}</ItemTitle>
          <ItemDetails>{detail}</ItemDetails>
        </DetailCover>
        <IconCover>
          <Icon src={src} alt={alt} />
        </IconCover>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.div`
  width: 100%;
  height: auto;
  margin: 4rem 0;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  margin: 0 auto;
  text-align: left;
  @media only screen and (max-width: 75em) {
    width: 85%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 90%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 96%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 75%;
  }
`
const IconCover = styled.div`
  flex: 0.8;
`
const Icon = styled.img`
  width: 15rem;
  height: auto;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 10rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const DetailCover = styled.div`
  text-align: left;
  flex: 2;
`

const ItemTitle = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;

  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const ItemDetails = styled.h4`
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 150%;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 1.5rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
