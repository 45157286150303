import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import { Phone, Mail } from "react-feather"

export function ContactAction() {
  return (
    <Cover>
      <CallLink href="tel:1300100428">
        <CallIcon /> 1300 100 428
      </CallLink>
      <EmailLink href="mailto:info@edigitalhub.com.au">
        <EmailIcon /> info@edigitalhub.com.au
      </EmailLink>
    </Cover>
  )
}

const Cover = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const CallLink = styled(Link)`
  padding: 0 2.5rem 1rem 0;
  font-size: 2rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${props => props.theme.color.dark};
`
const EmailLink = styled(CallLink)``

const CallIcon = styled(Phone)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
  margin: 0 1rem 0 0;
`
const EmailIcon = styled(Mail)`
  color: ${props => props.theme.color.highlight};
  width: 3.5rem;
  height: auto;
  margin: 0 1rem 0 0;
`
