import React from "react"
import styled from "styled-components"
import { ActionParagraph, Paragraphs } from "../typography/paragraphs"
import { CatchyHeadings } from "../typography/catchyHeadings"
import { ServiceTitle } from "../typography/headings"
import { Mail, Phone } from "react-feather"
import { Spacer } from "../elements"

export function GetInTouch() {
  return (
    <Section>
      <Wrapper>
        <TopSection>
          <Spacer>
            <CatchyHeadings>
              Reap the rewards of going digital now.
            </CatchyHeadings>
          </Spacer>
          <ServiceTitle>Get in touch with us</ServiceTitle>
        </TopSection>
        <ContentSection>
          <EmailSection href="mailto:info@edigitalhub.com.au">
            <Icon>
              <EmailIcon />
            </Icon>
            <DetailPart>
              <ActionParagraph>info@edigitalhub.com.au</ActionParagraph>
              <Paragraphs>Ready to make this your year?</Paragraphs>
            </DetailPart>
          </EmailSection>
          <PhoneSection href="tel:+0394577300">
            <Icon>
              <CallIcon />
            </Icon>
            <DetailPart>
              <ActionParagraph>+03 9457 7300</ActionParagraph>
              <Paragraphs>We’d love to hear from you.</Paragraphs>
            </DetailPart>
          </PhoneSection>
        </ContentSection>
      </Wrapper>
    </Section>
  )
}

const Section = styled.section`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.bgFirst};
  /* border-bottom-width: 0.6px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.theme.color
      .catchy};
  border-top-width: 0.6px;
  border-top-style: solid;
  border-top-color: ${props =>
    props.theme.color.catchy}; */
`
const Wrapper = styled.div`
  width: 95%;
  margin: auto auto;
  padding: 6rem 0.5rem;
  text-align: center;
  @media only screen and (max-width: 75em) {
    width: 95%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 95%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 75%;
  }
`

const TopSection = styled.div``

const ContentSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const EmailSection = styled.a`
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 2rem 1rem;
  padding: 2rem 1.2rem;
  border: 1.2px solid ${props => props.theme.color.complimentry};
  border-radius: 1rem;
`

const PhoneSection = styled(EmailSection)`
  /* text-align: left;
  text-decoration: none; */
`

const Icon = styled.div`
  width: auto;
  height: 100%;
  margin: 0 2rem 0 0;
`

const DetailPart = styled.div``

const EmailIcon = styled(Mail)`
  width: 7rem;
  height: 100%;
  stroke-width: 1px;
  color: ${props => props.theme.color.highlight};
`
const CallIcon = styled(Phone)`
  width: 7rem;
  height: 100%;
  stroke-width: 1px;
  margin: 0 2.5rem 0 0;
  color: ${props => props.theme.color.highlight};
`
