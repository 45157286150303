import React from "react"
import styled from "styled-components"
import { Button } from "../elements"
import { CatchyHeadings } from "../typography"

export function ServiceDetail({ children }) {
  return <Section>{children}</Section>
}

const Section = styled.main`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
`

export function IntroSection({ color, title, details, capitle }) {
  return (
    <IntroCover>
      <IntroWrapper>
        {/* <IntroTitle color={color}>{title}</IntroTitle> */}
        <CatchyHeadings capitle={capitle}>{title}</CatchyHeadings>
        <IntroDetail>{details}</IntroDetail>
      </IntroWrapper>
    </IntroCover>
  )
}

const IntroCover = styled.div`
  width: 100%;
`
const IntroWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 50%;
  }
`
// const IntroTitle = styled.h1`
//   padding: 1rem 0;
//   font-weight: bold;
//   font-size: 3.5rem;
//   color: ${props => props.color || props.theme.color.complimentry};
//   @media only screen and (max-width: 37.5em) {
//     font-size: 3rem;
//   }
// `
const IntroDetail = styled.h4`
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.color || props.theme.color.dark};
`

export function ProductDetails({ title, children, to }) {
  return (
    <ProductCover>
      <ProductWrapper>
        <ProductTitle>{title}</ProductTitle>
        <ItemsCover>{children}</ItemsCover>
        <Button to={to}>contact us</Button>
      </ProductWrapper>
    </ProductCover>
  )
}
const ProductCover = styled.div`
  width: 100%;
`
const ProductWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 50%;
  }
`
const ProductTitle = styled.h2`
  padding: 3rem 0 0 0;
  font-weight: bold;
  font-size: 3rem;
  line-height: 1.5;
  color: ${props => props.theme.color.dark};
  @media only screen and (max-width: 37.5em) {
    font-size: 2.5rem;
  }
`
const ItemsCover = styled.div`
  width: 100%;
  height: auto;
  padding: 5rem 0;
`

export function ReasonSection({
  borderColor,
  title,
  details,
  details1,
  details2,
}) {
  return (
    <ReasonCover>
      <ReasonWrapper>
        <ReasonTitle>{title}</ReasonTitle>
        <DetailCover borderColor={borderColor}>
          <ReasonDetail>{details}</ReasonDetail>
          <ReasonDetail>{details1}</ReasonDetail>
          <ReasonDetail>{details2}</ReasonDetail>
        </DetailCover>
      </ReasonWrapper>
    </ReasonCover>
  )
}

const ReasonCover = styled.div`
  width: 100%;
  margin: 2rem 0;
`
const ReasonWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 50%;
  }
`
const ReasonTitle = styled.h1`
  padding: 3rem 0;
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 1.5;
  color: ${props => props.theme.color.dark};
  @media only screen and (max-width: 37.5em) {
    font-size: 2.5rem;
  }
`

const DetailCover = styled.div`
  border: 2px solid ${props => props.borderColor};
  border-radius: 1rem;
  padding: 1rem;
`
const ReasonDetail = styled.h4`
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1.5;
  text-align: left;
  color: ${props => props.color || props.theme.color.dark};
`
