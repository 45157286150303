import React from "react"
import styled from "styled-components"

export function Paragraphs({ children }) {
  return <Paragraph>{children}</Paragraph>
}
export function MediumParagraphs({ children, textalign }) {
  return <MParagraph textalign={textalign}>{children}</MParagraph>
}
export function SmallParagraphs({ children }) {
  return <SParagraph>{children}</SParagraph>
}
export function ServiceParagraph({ children }) {
  return <SerParagraph>{children}</SerParagraph>
}

export function ActionParagraph({ children }) {
  return <ActParagraph>{children}</ActParagraph>
}
export function TinyParagraph({ children }) {
  return <TinyPara>{children}</TinyPara>
}

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`
const SParagraph = styled.p`
  font-weight: normal;
  font-size: 1.9rem;
  line-height: 135%;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`
const MParagraph = styled.p`
  text-align: ${props => props.textalign};
  font-weight: normal;
  font-size: 1.9rem;
  line-height: 135%;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`

const SerParagraph = styled.p`
  font-weight: normal;
  font-size: 1.7rem;
  line-height: 135%;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`
const ActParagraph = styled.p`
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2rem;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`

const TinyPara = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 135%;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  color: ${props => props.theme.color.dark};
`
