import { Link } from "gatsby"
import React, { useState } from "react"
import { Mail, PhoneCall, X } from "react-feather"
import styled, { keyframes } from "styled-components"
import { Caption, Paragraphs } from "../typography"
import { Form } from "./form"

export function CtaMail({ href, show }) {
  return (
    <Circle show={show} href="mailto:info@edigitalhub.com.au">
      <MailIcon />
    </Circle>
  )
}

const Animation = keyframes`
  from {
    opacity:0.5;
    transform:translateX(10rem)
  }
`

const Circle = styled.a`
  opacity: 1;
  display: ${props => props.show};
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: ${props => props.theme.color.catchy};
  border-radius: 50%;
  padding: 1.2rem;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  animation: ${Animation} 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: ${props => props.theme.color.dark};
`

const MailIcon = styled(Mail)`
  width: 2.3rem;
  height: auto;
`

export function CtaFaq({ href, display }) {
  return (
    <HelpCover display={display} href="tel:+0394577300">
      <HelpIcon />
    </HelpCover>
  )
}

const AnimationFaq = keyframes`
  from {
    transform:translateX(-10rem)
  }
`

const HelpCover = styled.a`
  display: ${props => props.display};
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  background-color: ${props => props.theme.color.catchy};
  border-radius: 50%;
  padding: 1.2rem;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  animation: ${AnimationFaq} 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: ${props => props.theme.color.dark};
`

const HelpIcon = styled(PhoneCall)`
  width: 2.3rem;
  height: auto;
`

export function CtaButton() {
  const [popOptions, setPopOptions] = useState("hidden")

  function onHoverFunc() {
    setPopOptions("visible")
  }
  function onHoverOutFunc() {
    setPopOptions("hidden")
  }
  return (
    <>
      <Cover to="" onClick={onHoverFunc}>
        <LoginCover>Free Quote</LoginCover>
      </Cover>
      <PopupCover>
        <PopUp pop={popOptions}>
          <CloseCover>
            <Caption color={props => props.theme.color.highlight}>
              Send us your inquiry
              <br />
              <Paragraphs>We will get back to you shortly</Paragraphs>
            </Caption>
            <CloseIcon onClick={onHoverOutFunc} />
          </CloseCover>
          <PopupContent>
            <Form />
          </PopupContent>
        </PopUp>
      </PopupCover>
    </>
  )
}

const Cover = styled.div`
  writing-mode: vertical-lr;
  cursor: pointer;
`
const LoginCover = styled(Link)`
  position: fixed;
  right: 0;
  top: 40vh;
  z-index: 200;
  transform: rotate(180deg);
  text-align: center;
  background-color: ${props => props.btncolor || props.theme.color.catchy};
  border-radius: 0 0.5rem 0.5rem 0;
  color: ${props => props.color || props.theme.color.dark};
  font-family: ${props => props.theme.font.heading};
  font-size: 1.7rem;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
  padding: 1rem 1rem;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 75em) {
    right: 0;
    top: 30vh;
  }
  @media only screen and (max-width: 56.25em) {
    right: 0;
    top: 30vh;
  }
  @media only screen and (max-width: 37.5em) {
    right: 0;
    top: 30vh;
  }
  @media only screen and (min-width: 112.5em) {
    right: 0;
    top: 40vh;
    font-size: 1.6rem;
  }
`

const PopupCover = styled.div`
  width: 50%;
  height: auto;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 70%;
    height: auto;
  }
  @media only screen and (max-width: 56.25em) {
    width: 80%;
    height: auto;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: auto;
  }
`

const PopUp = styled.div`
  visibility: ${props => props.pop};
  background-color: ${props => props.theme.color.light};
  box-shadow: 0px -50px 100px rgba(0, 0, 0, 0.45);
  border-radius: 1rem 1rem 0px 0px;
  position: fixed;
  z-index: 3000;
  bottom: 0;
  padding: 1rem;
  width: 50%;
  height: auto;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 70%;
    height: auto;
  }
  @media only screen and (max-width: 56.25em) {
    width: 80%;
    height: auto;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: auto;
  }
`
const CloseCover = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
`
const PopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const CloseIcon = styled(X)`
  width: 5rem;
  height: 5rem;
`
