import React from "react"
import styled from "styled-components"

import process from "../../images/illustrators/process.svg"
import {
  CatchyHeadings,
  SubTitle,
  SmallParagraphs,
  Caption,
  ServiceParagraph,
} from "../typography"

import { Button } from "../elements"

export function WhyChooseUs({
  smallTitle = "There is a flood of competition out there, but eDigitalHub stands out from the crowd. Here’s what makes us different",
  detail1 = " We understand that each business is unique. Rather than using the same strategy for every single client, we always begin by conducting thorough research and identifying your personal strengths and objectives. Your business is distinct and your website design should be too.",
  detail2 = " Our team has years of experience behind them. In that time, we’ve acquired enough knowledge and skills to count ourselves experts in the website design and telecommunications field. Providing  everything from SEO to site migration, we’re a digital marketing  agency you can rely on.",
  detail3 = "We have a proven track record of satisfied customers and  businesses we’ve grown. Time and time again, we’ve seen our world-class website design, SEO and digital marketing strategies convert and enhance the customer bases of our clients.",
}) {
  return (
    <Section>
      <Wrapper>
        <TopSection>
          <Content>
            <CatchyHeadings>Why us.</CatchyHeadings>
            <SubTitle>Why choose us</SubTitle>
            <SmallParagraphs>{smallTitle}</SmallParagraphs>
          </Content>
          <ImageContainer>
            <Illustrator src={process} alt="our process" />
          </ImageContainer>
        </TopSection>

        <BottomSection>
          <ContentBox>
            <Caption>Customised Approach</Caption>
            <ServiceParagraph>{detail1}</ServiceParagraph>
          </ContentBox>
          <ContentBox>
            <Caption>Team of Experts</Caption>
            <ServiceParagraph>{detail2}</ServiceParagraph>
          </ContentBox>
          <ContentBox>
            <Caption>Proven track record</Caption>
            <ServiceParagraph>{detail3}</ServiceParagraph>
          </ContentBox>
        </BottomSection>

        <ButtonCover>
          <Button to="/about-us/">Read more</Button>
        </ButtonCover>
      </Wrapper>
    </Section>
  )
}

const Section = styled.section`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.bgFirst};
  /* border-bottom-width: 0.6px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.theme.color
      .catchy};
  border-top-width: 0.6px;
  border-top-style: solid;
  border-top-color: ${props =>
    props.theme.color.catchy}; */
`

const Wrapper = styled.div`
  width: 80%;
  margin: auto auto;
  padding: 6rem 1.5rem;
  @media only screen and (max-width: 75em) {
    width: 85%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 85%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 97%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 75%;
  }
`

const TopSection = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

const ImageContainer = styled.div`
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    text-align: center;
  }
`

const Illustrator = styled.img`
  width: 55rem;
  height: auto;
  @media only screen and (max-width: 75em) {
    width: 35rem;
  }
  @media only screen and (max-width: 56.25em) {
    width: 35rem;
  }
  @media only screen and (max-width: 37.5em) {
    width: 35rem;
  }
  @media only screen and (min-width: 112.5em) {
    width: 70rem;
  }
`

const Content = styled.div`
  width: 45%;
  /* flex: 1; */
  align-self: center;
  @media only screen and (max-width: 75em) {
    width: 45%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 45%;
    text-align: center;
  }
  @media only screen and (max-width: 37.5em) {
    /* text-align: center; */
    width: 100%;
  }
`

const BottomSection = styled.div`
  width: 95%;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 85%;
  }
`
const ContentBox = styled.div`
  margin: 0rem 0 2rem 0;
  padding: 2rem;
  background-color: ${props => props.theme.color.light};
  border-radius: 1rem;
`
const ButtonCover = styled.div`
  text-align: center;
  padding: 3rem 0;
`
