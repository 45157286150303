import React from "react"
import styled from "styled-components"

import idea from "../../images/illustrators/idea.svg"
import {
  CatchyHeadings,
  SubTitle,
  SmallParagraphs,
  Caption,
} from "../typography"
import { Card } from "../elements/card"

import perfection from "../../images/about/perfection.svg"
import principle from "../../images/about/principle.svg"
import peoplefirst from "../../images/about/peoplefirst.svg"
import { Button } from "../elements"

export function OurGoal() {
  return (
    <>
      <Details>
        <Caption>
          Our digital marketing agency is driven by three values
        </Caption>
      </Details>
      <BottomSection>
        <Card
          src={perfection}
          title="Perfection"
          detail="We strive to achieve premium results in everything we do, always going above and beyond to deliver for our clients."
        />
        <Card
          src={principle}
          title="Principle"
          detail="We always uphold our integrity and strong moral principles. Our business succeeds through hard work and dedication."
        />
        <Card
          src={peoplefirst}
          title="People First"
          detail="We may operate in a digital space, but we always remember that we’re dealing with real people. Customer satisfaction is our top priority."
        />
      </BottomSection>
      <ButtonCover>
        <Button to="/about-us/">Read more</Button>
      </ButtonCover>
    </>
  )
}

export function History() {
  return (
    <Section>
      <TopSection>
        <Illustrator src={idea} alt="who we are" />
        <Content>
          <CatchyHeadings>The core of our business.</CatchyHeadings>
          <SubTitle>Who we are?</SubTitle>
          <SmallParagraphs>
            eDigitalHub is a full-service digital marketing agency with offices
            in Melbourne and Sydney. We’re a team of digital gurus with a
            passion for helping our customers realise their biggest goals. The
            two pillars of our business are website design and digital
            marketing, and we use these strategies to help your company soar.
          </SmallParagraphs>
        </Content>
      </TopSection>
      <OurGoal />
    </Section>
  )
}

const Section = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 5rem 0;
  background-color: ${props => props.theme.color.light};
  /* border-bottom-width: 0.6px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.theme.color
      .catchy};
  border-top-width: 0.6px;
  border-top-style: solid;
  border-top-color: ${props =>
    props.theme.color.catchy}; */
`

const TopSection = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 75em) {
    width: 99%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 56.25em) {
    width: 99%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
`

const Details = styled.div`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 5rem 0;
`

const BottomSection = styled.div`
  width: 97%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 97%;
  }
`

const Illustrator = styled.img`
  width: 60rem;
  height: auto;
  @media only screen and (max-width: 75em) {
    width: 45rem;
  }
  @media only screen and (max-width: 56.25em) {
    width: 45rem;
  }
  @media only screen and (max-width: 37.5em) {
    width: 35rem;
    margin: 0 auto;
    order: 1;
  }
  @media only screen and (min-width: 112.5em) {
    width: 85rem;
  }
`

const Content = styled.div`
  width: 45%;
  padding: 5rem 0 0 0;
  /* flex: 1; */
  justify-self: center;
  align-self: flex-end;
  @media only screen and (max-width: 75em) {
    /* width: auto; */
    padding: 5rem 3rem 0 3rem;
  }
  @media only screen and (max-width: 56.25em) {
    /* width: auto; */
    padding: 5rem 1rem 0 3rem;
    text-align: center;
  }
  @media only screen and (max-width: 37.5em) {
    width: auto;
    padding: 5rem 0 0 0;
  }
`
const ButtonCover = styled.div`
  text-align: center;
  padding: 5rem 0;
`
