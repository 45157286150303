import React from "react"
import styled from "styled-components"
import { ServiceTitle, ServiceParagraph } from "../typography"
import { Spacer } from "."
import { Link } from "gatsby"

export function ServicesCard({ src, title, detail, to }) {
  return (
    <Animation
    // data-sal-duration="1500"
    // data-sal="slide-up"
    // data-sal-delay="300"
    // data-sal-easing="ease-out-back"
    >
      <Cover to={to}>
        <Container>
          <ImageIcon src={src} alt={src} />
          <SerTitle>{title}</SerTitle>
        </Container>
        <Wrapper>
          <Spacer />
          <ServiceParagraph>{detail}</ServiceParagraph>
        </Wrapper>
        {/* <Button to={to}>Read more</Button> */}
      </Cover>
    </Animation>
  )
}

const Container = styled.div`
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    display: grid;
    grid-template-columns: 1fr, 1fr;
    grid-column-gap: 1rem;
    align-content: flex-start;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
const SerTitle = styled.h1`
  grid-column: 2/3;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 4.9rem;
  color: ${props => props.color || props.theme.color.dark};
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.2rem;
  color: ${props => props.theme.color.dark};
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    display: grid;
    /* grid-template-columns: 1fr, 1fr; */
    grid-column-gap: 1rem;
    font-size: 2rem;
    /* justify-items: center; */
    align-content: center;
    text-align: left;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

// const Button = styled(Link)`
//   align-self: center;
//   text-decoration: none;
//   text-transform: uppercase;
//   display: inline-flex;
//   flex-direction: row;
//   align-items: center;
//   cursor: pointer;
//   background-color: ${props => props.theme.color.highlight};
//   color: ${props => props.theme.color.white};
//   padding: 1rem 2rem;
//   border-radius: 0.5rem;
//   letter-spacing: 2px;
//   font-family: ${props => props.theme.font.normal};
//   font-weight: 600;
//   font-size: 1.6rem;
//   line-height: 2.7rem;
//   &:hover {
//     box-shadow: ${props => props.theme.shadow.button};
//   }
// `

const Animation = styled.div`
  flex: 0 0 auto;
`

const Cover = styled(Link)`
  text-decoration: none;
  text-align: center;
  max-width: 37rem;
  min-height: 35rem;
  width: auto;
  height: auto;
  background: ${props => props.theme.color.light};
  border: 0.5px solid ${props => props.theme.color.complimentry};
  border-radius: 10px;
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem 1.5rem 1rem;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 37rem;
    min-height: auto;
    align-items: flex-start;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const Wrapper = styled.div`
  height: auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    display: none;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const ImageIcon = styled.img`
  margin: 0 0 1rem 0;
  width: 10rem;
  height: auto;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 5rem;
    height: auto;
    grid-column: 1/2;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
