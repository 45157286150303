import React from "react"
import styled from "styled-components"

import DarkBrand from "../../images/LogoDark.png"

export function LogoDark() {
  return <Dark src={DarkBrand} alt="eDigitalHub Pty Ltd" />
}

const Dark = styled.img`
  width: 100%;
  height: 100%;
`
